import '../styles/index.scss';

require('../favicon.png')

import vipSVG from '../img/vip-logo.svg';

var vipLogo = document.getElementById('vip');
vipLogo.src = vipSVG;

// ---------------------- o

import isSVG from '../img/logo-interactive.svg';

var interactiveLogo = document.getElementById('interactive');
interactiveLogo.src = isSVG;

// ---------------------- o

import okiaSVG from '../img/logo-okia.svg';

var okiaLogo = document.getElementById('okia');
okiaLogo.src = okiaSVG;

// ---------------------- o

import lionsSVG from '../img/logo-new-sales-lions.svg';

var lionsLogo = document.getElementById('lions');
lionsLogo.src = lionsSVG;

// ---------------------- o

import isaSVG from '../img/logo-isa.svg';

var isaLogo = document.getElementById('isa');
isaLogo.src = isaSVG;

// ---------------------- o